<div class="box-header--table">
  <div class="subheader">
    <span class="subtitle">{{headerBoxTableSettings.headerTitle}}</span>
    <div class="left">
      @if(headerBoxTableSettings.showTextBox) {
      <div class="search_box" [ngClass]="{
        'rede_mulher_input_image': urlActive.includes(portalTypes.REDE_MULHER),
        'rj190_input_image': urlActive.includes(portalTypes.RJ_190),
        'rede_escola_input_image': urlActive.includes(portalTypes.REDE_ESCOLA)}">
        <input type="text" [(ngModel)]="searchTextValue" [placeholder]="headerBoxTableSettings.titleSearch">
      </div>
      }

      <div class="filter_box" [ngClass]="{
        'rede_mulher_filter_box_image': urlActive.includes(portalTypes.REDE_MULHER),
        'rj190_filter_box_image': urlActive.includes(portalTypes.RJ_190),
        'rede_escola_filter_box_image': urlActive.includes(portalTypes.REDE_ESCOLA)}">
        @if(headerBoxTableSettings.showFilterBox) {
        <select [(ngModel)]="filterValue">
          @for (option of headerBoxTableSettings.optionsFilter; track $index) {
          @if(option.default) {
          <option disabled selected value="undefined">{{option.name}}</option>
          } @else {
          <option [value]="option.value">{{option.name}}</option>
          }
          }
        </select>
        }
      </div>

      @if(headerBoxTableSettings.showExcelButton) {
      <button class="button-excel" (click)="excelButtonClick.emit()">
        <img src="assets/excel_ic.svg">
        <span class="label"> Excel</span></button>
      }

      @if (showButtonClearSearch) {
      <app-dynamic-button [buttonConfig]="configButtonClearSearch"></app-dynamic-button>
      }
      <app-dynamic-button [buttonConfig]="configButtonSearch"></app-dynamic-button>
    </div>
  </div>
  <div class="table mb-0">
    <app-dynamic-table [tableSettings]="tableSettings" [buttonTable]="tableSettings.buttonTable"
      [tableBody]="tableSettings.tableBody"></app-dynamic-table>
  </div>
</div>
