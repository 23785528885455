import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';

import { AnnoucementsService } from './services/annoucements.service';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { ModalService } from '../modal/services/modal.service';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { EXTRAS_PAGES_URLS } from '../../enums/extras-pages-urls.enum';

@Component({
  selector: 'app-announcement',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent],
  templateUrl: './announcement.component.html',
  styleUrl: './announcement.component.scss',
})
export class AnnouncementComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({});
  annoucements: any = null;
  annoucements$: Subscription;
  disableButtons: boolean = true;
  currentPage: number = 0;
  totalPages: number = 5;
  pageNumberList: Array<number> = [];
  portalURLS = PORTAL_TYPE_URLS;
  currentURL: string = '';
  headerTitles = ['', 'Imagem', 'Ordem', 'Nome do arquivo', 'Link', 'Status', ''];

  constructor(
    private _formBuilder: FormBuilder,
    private _annoucementsService: AnnoucementsService,
    private _router: Router,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.setCurrentURL();
    this.initializeForm();
    this.getAnnoucements({ queryPage: 1 });
  }

  initializeForm() {
    this.formGroup = this._formBuilder.group({});
  }

  setCurrentURL() {
    this.currentURL = this._router.url;
  }

  getAnnoucements(annoucementQuery: { queryPage: number }) {
    this.annoucements$ = this._annoucementsService
      .getAnnoucements(annoucementQuery)
      .pipe(take(1))
      .subscribe((annoucements) => {
        this.clearFormGroup();
        this.setPagination(annoucements);
        this.annoucements = annoucements.annoucementsList;
        this.annoucements.forEach((annoucement: any) => {
          this.formGroup.addControl(annoucement.id, new FormControl(false));
        });
      });
    this.formGroup.valueChanges.subscribe((annoucements: Array<any>) => {
      this.disableButtons = Object.values(annoucements).indexOf(true) === -1;
    });
  }

  updatePage(newPage: number) {
    this.getAnnoucements({ queryPage: newPage });
  }

  clearFormGroup() {
    Object.keys(this.formGroup.controls).forEach((controlName) => {
      this.formGroup.removeControl(controlName);
    });
  }

  setPagination(annoucements: any) {
    this.currentPage = annoucements.currentPage;
    this.totalPages = annoucements.totalPages;
    this.pageNumberList = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
  }

  formatPageNumbers(pageNumber: number) {
    return {
      active_rede_mulher_color:
        this.currentPage === pageNumber &&
        this.currentURL.includes(this.portalURLS.REDE_MULHER),
      active_rj190_color:
        this.currentPage === pageNumber &&
        this.currentURL.includes(this.portalURLS.RJ_190),
      active_rede_escola_color:
        this.currentPage === pageNumber &&
        this.currentURL.includes(this.portalURLS.REDE_ESCOLA),
    };
  }

  changeAnnoucementsState(operation: string) {
    const annoucementCheckboxes = this.formGroup.value;
    Object.keys(annoucementCheckboxes).forEach((key) => {
      const checked: boolean = annoucementCheckboxes[key];
      if ((operation === 'activate' || operation === 'deactivate') && checked) {
        this.annoucements.find(
          (annoucement: any) => annoucement.id == key
        ).status = operation === 'activate' ? 'active' : 'inactive';
      } else if (checked) {
        const deleteIndex: number = this.annoucements.findIndex(
          (annoucement: any) => annoucement.id == key
        );
        this.annoucements.splice(deleteIndex, 1);
      }
      this.formGroup.reset();
    });
  }

  goToAnnoucementDetail() {
    this._router.navigateByUrl(
      this._router.url + '/' + EXTRAS_PAGES_URLS.ADD_ANNOUNCEMENTS
    );
  }

  openModal(modalTemplate: TemplateRef<any>) {
    this.modalService.open(modalTemplate).subscribe((action: any) => {
      if (action) {
        this.changeAnnoucementsState('delete');
      }
    });
  }

  ngOnDestroy(): void {
    this.annoucements$.unsubscribe();
  }
}
