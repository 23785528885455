import { Component } from '@angular/core';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { generateLoremText, getPortalTypeFromLink } from '../../utils';
import { PortalFilterCard } from '../../models/portal-filter-card';
import { PORTAL_FILTER_CARD_TITLES } from '../filter-card/enums/filter-card-titles.enum';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { PopAlertComponent } from '../pop-alert/pop-alert.component';
import { PopAlertModel } from '../pop-alert/pop-alert.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-report-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './view-report-page.component.html',
  styleUrl: './view-report-page.component.scss'
})
export class ViewReportPageComponent {
  reported = {
    violations: [
      { id: 1, name: 'Violação 1' },
      { id: 2, name: 'Violação 2' },
      { id: 3, name: 'Violação 3' },
      { id: 4, name: 'Violação 4' },
      { id: 5, name: 'Violação 5' },
    ],
    whatToDo: [
      { id: 1, name: 'Manter a publicação' },
      { id: 2, name: 'Deletar a publicação' },
      { id: 3, name: 'Deletar a publicação e bloquear o usuário autor' },
    ],
    images: [
      {},
      {},
      {},
      {}
    ]
  };
  portal: string;
  portals = PORTAL_TYPE_URLS;
  reportData: any;
  title: string = "";
  imgProfile: string;
  showPage: boolean = false;

  formGroup: FormGroup = new FormGroup({});
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/back_arrow_ic.svg", handler: () => this.backPage() };
  pageOnlyShowData: boolean = true;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.reportData = window.history.state.reportData;
    if (!this.reportData.resume) this.reportData.resume = generateLoremText();
    this.portal = getPortalTypeFromLink(this.router.url);
    //validar como será feito, protendo a URL por enquanto.
    if (!this.reportData) return this.navToRetrocessPage();
    this.initializeFormGroup();
    this.title = this.buildPage(window.history.state.card);
    this.imgProfile = 'url("../../../../assets/user_img.svg")';
    this.showPage = true;
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      violation: [0, [Validators.required, Validators.min(1)]],
      whatToDo: [0, [Validators.required, Validators.min(1)]],
    });
  }

  backPage() {
    window.history.back();
  }

  saveUser() {
    if (this.formGroup.valid) {
      let portal = getPortalTypeFromLink(this.router.url);
      let page = portal == PORTAL_TYPE_URLS.REDE_MULHER ? SIDEBAR_OPTIONS_URLS.GENERAL_PANEL : SIDEBAR_OPTIONS_URLS.REPORTED;
      this.router.navigateByUrl(`${portal}/${page}`).then(() => this.openDialog());
    }
  }

  openDialog(): void {
    let modelPopAlert: PopAlertModel = {
      text: "Análise feita com sucesso!",
      type: 'success'
    };
    const dialogRef = this.dialog.open(PopAlertComponent, {
      data: modelPopAlert,
      position: { top: '52px' },
      hasBackdrop: false
    });

    setTimeout(() => {
      dialogRef.close();
    }, 2000);
  }

  buildPage(card: PortalFilterCard): string {
    if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
      this.pageOnlyShowData = false;
      return "Análise Usuário";
    }
    else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) {
      this.pageOnlyShowData = false;
      return "Análise Usuário";
    }
    else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED) {
      this.pageOnlyShowData = true;
      this.formGroup.setValue({
        violation: 2,
        whatToDo: 2
      });
      this.formGroup.get('violation')?.disable();
      this.formGroup.get('whatToDo')?.disable();
      return "Usuário Bloqueado";
    }
    else if (card.title == PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS) {
      this.pageOnlyShowData = true;
      this.formGroup.setValue({
        violation: 4,
        whatToDo: 1
      });
      this.formGroup.get('violation')?.disable();
      this.formGroup.get('whatToDo')?.disable();
      return PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS;
    }
    else if (card.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED) {
      this.pageOnlyShowData = false;
      return "Análise Usuário";
    }

    else if (card.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
      this.pageOnlyShowData = false;
      return "Denúncia";
    }

    return "";
  }

  navToRetrocessPage() {
    let page = this.portal == PORTAL_TYPE_URLS.RJ_190 ? SIDEBAR_OPTIONS_URLS.REPORTED : SIDEBAR_OPTIONS_URLS.GENERAL_PANEL;
    this.router.navigateByUrl(`${this.portal}/${page}`);
  }
}
