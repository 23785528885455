import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription, of } from 'rxjs';
import { PORTAL_FILTER_CARD_TITLES } from '../../../shared/components/filter-card/enums/filter-card-titles.enum';
import { FilterCardComponent } from '../../../shared/components/filter-card/filter-card.component';
import { FilterCardsService } from '../../../shared/components/filter-card/services/filter-cards.service';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { HeaderTableSettings } from '../../../shared/components/header-box-table/header-box-table.model';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { PORTAL_TYPES } from '../../../shared/enums/portal-types.enum';
import { PortalFilterCard } from '../../../shared/models/portal-filter-card';
import { ActivatedRoute, Router } from '@angular/router';
import { EXTRAS_PAGES_URLS } from '../../../shared/enums/extras-pages-urls.enum';

@Component({
  selector: 'app-reported-page190rj',
  standalone: true,
  imports: [
    HeaderBoxTableComponent,
    SidebarOptionTitleBandComponent,
    FilterCardComponent,
  ],
  templateUrl: './reported-page190rj.component.html',
  styleUrl: './reported-page190rj.component.scss',
})
export class ReportedPage190rjComponent implements OnInit, OnDestroy {
  portalCards: Array<PortalFilterCard> = [];
  portalCardsSubscription: Subscription | null = null;
  selectedPortalFilterCard: PortalFilterCard | null = null;
  PORTAL_FILTER_CARD_TITLES = PORTAL_FILTER_CARD_TITLES;
  reportedsData: any[];
  tableConfig: any = {};
  showTable: boolean = false;
  myEventSubscribeCurrentPortal: Subscription | null = null;

  constructor(
    private _portalFilterCardsService: FilterCardsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.emitNewPortalFilterCards();
    this.listenToNewPortalCards();
    this.listenToChangesOnSelectedPortalFilterCard();
    await this.getConfigTable();
  };

  ngOnDestroy() {
    if (this.portalCardsSubscription) this.portalCardsSubscription?.unsubscribe();
    if (this.myEventSubscribeCurrentPortal) this.myEventSubscribeCurrentPortal?.unsubscribe();
  }

  emitNewPortalFilterCards() {
    let newFiltersCards = [
      { id: 1, title: 'Reports', value: 10000 },
      { id: 2, title: 'Status Aberto', value: 10000 },
      { id: 3, title: 'Bloqueados', value: 10000 },
      { id: 4, title: 'Status Fechado', value: 10000 },
    ];
    this._portalFilterCardsService.emitNewPortalFilterCards(
      PORTAL_TYPES.RJ_190,
      newFiltersCards
    );
  }

  listenToNewPortalCards() {
    this.portalCardsSubscription =
      this._portalFilterCardsService.portalFilterCard$.subscribe(
        (portalCards: Array<PortalFilterCard>) => {
          this.portalCards = portalCards;
        }
      );
  }

  listenToChangesOnSelectedPortalFilterCard() {
    this.myEventSubscribeCurrentPortal = this._portalFilterCardsService.currentPortalFilterCard$.subscribe(
      (selectedPortalFilterCard: PortalFilterCard | null) => {
        if (selectedPortalFilterCard) {
          this.selectedPortalFilterCard = selectedPortalFilterCard;
          this.portalCards.forEach((portalCard: PortalFilterCard) => {
            portalCard.active = false;
            if (portalCard.id === selectedPortalFilterCard.id) {
              portalCard.active = true;
            }
          });
        }
      }
    );
  }

  getConfigTable(): Promise<boolean> {
    return new Promise(resolve => {
      this.getData().subscribe(
        {
          next: (response: HeaderTableSettings) => {
            this.tableConfig = response;
            this.reportedsData = response.tableBody;
            this.tableConfig.buttonTable.handler = (dataObjectTable: any) => {
              this.openVisualize(dataObjectTable);
            };
            resolve(true);
          },
          error: () => {
            console.log('error api');
            resolve(true);
          }
        }
      )
    })
  }

  public searchAndFilterItens(event: any) {
    let newArray: any = [];
    this.reportedsData.forEach((elementArray) => {
      if (event?.searchValue) {
        if (this.validateObject(elementArray, event.searchValue)) {
          newArray.push(elementArray);
        }
      }
    });
    this.tableConfig.tableBody = newArray;
  }

  public validateObject(object: any, textValue: string): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS:
        if (
          object.name.toString().toLowerCase().includes(textValue) ||
          object.date.toString().includes(textValue) ||
          object.hour.toString().includes(textValue) ||
          object.amount.toString().includes(textValue) ||
          object.resume.toString().toLowerCase().includes(textValue) ||
          object.status.toString().toLowerCase().includes(textValue)
        )
          return true;
        return false;
      case PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS:
        if (
          object.name.toLowerCase().includes(textValue) ||
          object.date.toString().includes(textValue) ||
          object.hour.toLowerCase().includes(textValue) ||
          object.amount.toString().toLowerCase().includes(textValue) ||
          object.resume.toLowerCase().includes(textValue)
        )
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED:
        if (
          object.name.toLowerCase().includes(textValue) ||
          object.cpf.toString().includes(textValue) ||
          object.phone.toString().includes(textValue) ||
          object.email.toLowerCase().includes(textValue) ||
          object.blockingDate.toString().toLowerCase().includes(textValue)
        )
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS:
        if (
          object.name.toLowerCase().includes(textValue) ||
          object.cpf.toString().includes(textValue) ||
          object.phone.toString().includes(textValue) ||
          object.email.toLowerCase().includes(textValue) ||
          object.blockingDate.toString().toLowerCase().includes(textValue)
        )
          return true;
        return false;

      default:
        return false;
    }
  }

  public clearSearchTable() {
    this.tableConfig.tableBody = this.reportedsData;
  }

  openVisualize(data: any) {
    this.router.navigate([EXTRAS_PAGES_URLS.VIEW_REPORTED], { relativeTo: this.route, state: { reportData: data, card: this.selectedPortalFilterCard } });
  }

  private getData(): Observable<any> {
    // return this._tableService.getObjectConfigTable(this.router.url, SIDEBAR_OPTIONS_URLS.REPORTED, this.selectedPortalFilterCard);
    return of({});
  }
}
