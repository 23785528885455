<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<section class="portal-body--container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="header">
          <p class="portal-body__title">Anúncios Publicados</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="portal-body__annoucement--table-card">
          <div class="row buttons-container">
            <div class="col-xl-2 col-6 px-0">
              <button (click)="goToAnnoucementDetail()" type="button"
                class="d-flex align-items-center justify-content-center annoucement-button add dynamic_button">
                <img src="./../../../../assets/adicionar-imagem.svg" />
                <span class="ml-5 text-nowrap">Adicionar img</span>
              </button>
            </div>
            <div class="col-xl-2 col-6 px-0">
              <button [disabled]="disableButtons" type="button"
                class="d-flex align-items-center justify-content-center annoucement-button activate dynamic_button"
                (click)="changeAnnoucementsState('activate')">
                <img src="./../../../../assets/ativar-on.svg" />
                <span class="ml-5">Ativar</span>
              </button>
            </div>
            <div class="col-xl-2 col-6 px-0">
              <button [disabled]="disableButtons" type="button"
                class="d-flex align-items-center justify-content-center annoucement-button deactivate dynamic_button"
                (click)="changeAnnoucementsState('deactivate')">
                <img src="./../../../../assets/inativar-on.svg" />
                <span class="ml-5">Inativar</span>
              </button>
            </div>
            <div class="col-xl-2 col-6 px-0">
              <button [disabled]="disableButtons" type="button"
                class="d-flex align-items-center justify-content-center annoucement-button delete dynamic_button"
                (click)="openModal(modalTemplate)">
                <img src="./../../../../assets/adicionar-imagem.svg" />
                <span class="ml-5">Deletar</span>
              </button>
            </div>
            <div class="col-xl-4 col-0"></div>
          </div>
          <div class="table mt-20">
            <div class="header">
              <div class="row row_title">
                @for (headerColumn of headerTitles; track $index; let first = $first; let last = $last;) {
                <div [ngClass]="{'title_column': !first && !last }">{{ headerColumn }}</div>
                }
              </div>
            </div>
            <div class="body">
              <form [formGroup]="formGroup">
                @for(annoucement of annoucements; track annoucement.id){
                <div class="container-fluid">
                  <div class="row py-20 row_texts">
                    <div class="d-flex mt-14">
                      <label class="container">
                        <input type="checkbox" [formControlName]="annoucement.id" />
                        <span class="checkmark"></span>
                      </label>
                    </div>

                    <div class="box-labels img-box px-0">
                      <img class="mx-auto img" src="./../../../../assets/brasao-pmerj.svg" />
                    </div>

                    <div class="box-labels m-auto px-0 text_column text-center label">
                      <span class="headerColumnName">{{headerTitles[2]}}:</span>
                      {{ annoucement.order }}
                    </div>
                    <div class="box-labels m-auto px-0" [attr.hover-tooltip]="annoucement.fileName">
                      <div class="p-0 text_column label">
                        <span class="headerColumnName">{{headerTitles[3]}}:</span>
                        {{ annoucement.fileName }}
                      </div>
                    </div>
                    <div class="box-labels m-auto" [attr.hover-tooltip]="annoucement.link">
                      <div class="p-0 text_column label">
                        <span class="headerColumnName">{{headerTitles[4]}}:</span>
                        {{ annoucement.link }}
                      </div>
                    </div>
                    <div class="box-labels statu-box d-flex px-0">
                      <div class="status-circle m-auto" [ngClass]="annoucement.status"></div>
                    </div>
                    <div class="box-labels no-border px-0">
                      <button (click)="goToAnnoucementDetail()" class="m-auto annoucement-button dynamic_button edit">
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
                }
              </form>
              @if (annoucements.length) {
              <div class="pagination">
                <button class="button_swap_page" [disabled]="currentPage == pageNumberList[0]"
                  (click)="updatePage(currentPage - 1)">
                  &lt;
                </button>
                <ul>
                  @for (pageNumber of pageNumberList; track $index) {
                  <li class="link" [ngClass]="formatPageNumbers(pageNumber)" (click)="updatePage(pageNumber)">
                    {{ pageNumber }}
                  </li>
                  }
                </ul>
                <button class="button_swap_page" [disabled]="
                    currentPage == pageNumberList[pageNumberList.length - 1]
                  " (click)="updatePage(currentPage + 1)">
                  &gt;
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #modalTemplate>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <img class="modal__warning-img--red mb-10" src="./../../../../assets/sidebar-emergency-red.svg" />
    <div class="modal__header-text--bold">Atenção</div>
    <div class="modal__body-text--bold">Você deseja deletar a imagem?</div>
  </div>
</ng-template>
