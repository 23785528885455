import { CommonModule } from '@angular/common';
import { Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DynamicTablePipe } from './dynamic-table.pipe';
import { ButtonEndTable, TableSettings } from './models/dynamic-table.model';
import { Router } from '@angular/router';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { cpfMask, phoneMask } from '../../utils';

@Component({
  selector: 'app-dynamic-table',
  standalone: true,
  imports: [CommonModule, DynamicTablePipe],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent implements OnInit, OnChanges {
  @Input() tableSettings: TableSettings;
  @Input() tableBody: any;
  @Input() buttonTable: ButtonEndTable;
  pages: number[];
  activeLink: number = 1;
  arrayPages: any = [];
  urlActive: string;
  portalTypes = PORTAL_TYPE_URLS;
  numberPages: any;
  lastPageIndexData: any;
  initPageIndexData: any;
  totalPageIndexData: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.urlActive = this.router.url;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableBody']?.currentValue) {
      let newArrayAfterFilter = changes['tableBody'].currentValue;
      this.maskData(newArrayAfterFilter);
      this.getNumPages(newArrayAfterFilter);
      this.activePage(1);
    }
    if (changes['buttonTable']?.currentValue) this.buttonTable = changes['buttonTable'].currentValue;

  };

  public bigSwapPage(nextPage: boolean) {
    if (nextPage) this.activeLink += 10;
    else this.activeLink -= 10;
    if (this.activeLink > this.numberPages) this.activeLink = this.numberPages;
    if (this.activeLink <= 0) this.activeLink = 1;
    // let data = this.showButtonClearSearch ? this.searchData : this.caravanData;
    this.arrayPages = this.paginateArray(this.tableSettings.tableBody, this.tableSettings.itensPerPage, this.activeLink);
    this.getNumPages(this.tableSettings.tableBody);
  }

  public swapPage(nextPage: boolean) {
    if (nextPage) this.activeLink += 1;
    else this.activeLink -= 1;
    this.arrayPages = this.paginateArray(this.tableSettings.tableBody, this.tableSettings.itensPerPage, this.activeLink);
    this.getNumPages(this.tableSettings.tableBody);
  };


  public activePage(pageNumber: number) {
    this.activeLink = pageNumber;
    this.arrayPages = this.paginateArray(this.tableSettings.tableBody, this.tableSettings.itensPerPage, this.activeLink);
  }

  public getNumPages(data: any) {
    let array: any[] = data;
    this.numberPages = Math.ceil(array.length / this.tableSettings.itensPerPage);
    this.pages = Array.from({ length: this.numberPages }, (_, i) => i + 1);
    this.pages = this.paginateLinks(this.numberPages);
    this.buildNumberIndexData(data);
  };

  public paginateLinks(links: any) {
    let newLinksArray: any = [];
    links = Array.from({ length: links }, (_, i) => i + 1);
    let max: number = 0;
    this.activeLink == 1 ? max = this.activeLink + 2 : max = this.activeLink + 1;
    let min = this.activeLink - 1;
    links.forEach((link: any) => {
      if (link >= min && link <= max) {
        newLinksArray.push(link);
      }
    });
    return newLinksArray;
  };

  public buildNumberIndexData(array: any) {
    this.lastPageIndexData = this.activeLink * this.tableSettings.itensPerPage;
    this.initPageIndexData = this.activeLink * this.tableSettings.itensPerPage - this.tableSettings.itensPerPage;
    this.totalPageIndexData = array.length;
    if (this.activeLink == 1) this.initPageIndexData = 1;
    if (this.lastPageIndexData > array.length) this.lastPageIndexData = array.length;
  }

  public paginateArray(array: any, page_size: any, page_number: any) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  public maskData(dataTable: any) {
    dataTable.forEach((uniqueData: any) => {
      if (uniqueData?.cpf && uniqueData?.cpf.match(/^[0-9]+$/) != null) uniqueData.cpf = cpfMask(uniqueData.cpf);
      if (uniqueData?.CPF && uniqueData?.CPF.match(/^[0-9]+$/) != null) uniqueData.CPF = cpfMask(uniqueData.CPF);
      if (uniqueData.phone && uniqueData?.phone.match(/^[0-9]+$/) != null) uniqueData.phone = phoneMask(uniqueData.phone);
      if (uniqueData.Telefone && uniqueData?.Telefone.match(/^[0-9]+$/) != null) uniqueData.Telefone = phoneMask(uniqueData.Telefone);
      if (uniqueData.DT_Validade) uniqueData.DT_Validade = uniqueData.DT_Validade.split('T', 1)[0];
    });
  }
}
