import { Component } from '@angular/core';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { ValidateCpf, getPortalTypeFromLink } from '../../utils';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { PopAlertModel } from '../pop-alert/pop-alert.model';
import { PopAlertComponent } from '../pop-alert/pop-alert.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-user-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SidebarOptionTitleBandComponent, DynamicButtonComponent, NgxMaskDirective],
  providers: [
    provideNgxMask(),
  ],
  templateUrl: './new-user-page.component.html',
  styleUrl: './new-user-page.component.scss'
})
export class NewUserPageComponent {
  annoucement = {
    organs: [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: '5' },
    ],
    profiles: [
      { id: 1, name: 'Ativo' },
      { id: 2, name: 'Inativo' },
    ],
  };

  formGroup: FormGroup = new FormGroup({});
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/back_arrow_ic.svg", handler: () => this.backPage() };

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      name: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],
      cpf: [, Validators.compose([Validators.required, ValidateCpf])],
      phone: [, [Validators.required]],
      cellphone: [, [Validators.required]],
      email: ['', Validators.compose([Validators.email])],
      organ: [0, [Validators.required, Validators.min(1)]],
      profile: [0, [Validators.required, Validators.min(1)]],
    });
  }

  backPage() {
    window.history.back();
  }

  saveUser() {
    console.log(this.formGroup.value);
    console.log(this.formGroup.valid);
    if (this.formGroup.valid) {
      let portal = getPortalTypeFromLink(this.router.url);
      this.router.navigateByUrl(`${portal}/${SIDEBAR_OPTIONS_URLS.USERS}`).then(() => this.openDialog());
    }
  }

  openDialog(): void {
    let modelPopAlert: PopAlertModel = {
      text: "Cadastro salvo com sucesso.",
      type: 'success'
    };
    const dialogRef = this.dialog.open(PopAlertComponent, {
      data: modelPopAlert,
      position: { top: '52px' },
      hasBackdrop: false
    });

    setTimeout(() => {
      dialogRef.close();
    }, 2000);
  }
}
