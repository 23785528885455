import { Injectable } from '@angular/core';
import { RestApiService } from '../rest-api/rest-api.service';
import { environment } from '../../../environments/environment.dev';
import { Observable } from 'rxjs';
import { IRestApiCall } from '../rest-api/rest-api-call';

@Injectable({
  providedIn: 'root'
})
export class Rj190Service {
  private readonly baseUrl = environment.baseUrl190RJ;
  constructor(
    private restApiService: RestApiService,
  ) { }

  getTotalStatics(): Observable<any> {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'painel/total',
    };
    return this.restApiService.get(request);
  };

  statiscsMonthlyUsers() {
    let year = new Date().getFullYear();
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/estatistica-mensal?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  activeInativeUsers() {
    let year = new Date().getFullYear();
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/total-ativos-inativos',
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyUsers(year: number) {
    const request: IRestApiCall = {
      baseUrl: this.baseUrl,
      endpoint: 'users/estatistica-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  emergencyTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'emergencias/top-rank',
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyEmegergency(year: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'emergencias/estatistica-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  emengercyTable() {
    let year = new Date().getFullYear();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'emergencias/detalhes-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyDenouncement(year: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'denuncias/estatistica-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  denouncementTops() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'denuncias/top-rank',
    };
    return this.restApiService.get(request);
  };

  denouncementTable() {
    let year = new Date().getFullYear();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'denuncias/detalhes-anual?ano=' + year
    };
    return this.restApiService.get(request);
  };

  statiscsYearlyMissing(year: number) {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'desaparecidos/estatistica-anual?ano=' + year,
    };
    return this.restApiService.get(request);
  };

  foungMissingTotal() {
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'desaparecidos/total-desaparecidos-encontrados',
    };
    return this.restApiService.get(request);
  };

  missingTable() {
    let year = new Date().getFullYear();
    const request: any = {
      baseUrl: this.baseUrl,
      endpoint: 'desaparecidos/detalhes-anual?ano=' + year
    };
    return this.restApiService.get(request);
  };
}
