import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { ImageUploadComponent } from './image-upload/image-upload.component';
import { Router } from '@angular/router';
import { SIDEBAR_OPTIONS_URLS } from '../../../enums/sidebar-option-urls.enum';
import { PORTAL_TYPE_URLS } from '../../../enums/portal-types-urls.enum';
import { ButtonConfig } from '../../dynamic-button/dynamic-button.model';
import { DynamicButtonComponent } from '../../dynamic-button/dynamic-button.component';
import { SidebarOptionTitleBandComponent } from '../../title-band/sidebar-option-title-band.component';

@Component({
  selector: 'app-add-announcement',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ImageUploadComponent, DynamicButtonComponent, SidebarOptionTitleBandComponent],
  templateUrl: './add-announcement.component.html',
  styleUrl: './add-announcement.component.scss',
})
export class AddAnnouncementComponent implements OnInit {
  fileUploaded: boolean = false;
  configButtonBack: ButtonConfig = { label: 'Voltar', imgSrc: "assets/back_arrow_ic.svg", handler: () => this.back() };
  annoucement = {
    orders: [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: '5' },
    ],
    status: [
      { id: 1, name: 'Ativo' },
      { id: 2, name: 'Inativo' },
    ],
  };

  formGroup: FormGroup = new FormGroup({});

  constructor(
    private _formBuilder: FormBuilder,
    private _location: Location,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  initializeFormGroup() {
    this.formGroup = this._formBuilder.group({
      fileName: ['', [Validators.required]],
      link: ['', [Validators.required]],
      order: [0, [Validators.required, Validators.min(1)]],
      status: [0, [Validators.required, Validators.min(1)]],
    });
  }

  checkValidForm() {
    const isFormValid: boolean = this.formGroup.valid;
    return isFormValid && this.fileUploaded;
  }

  saveAnnoucement() {
    if (this.checkValidForm()) {
      this._router.navigateByUrl(
        PORTAL_TYPE_URLS.RJ_190 + '/' + SIDEBAR_OPTIONS_URLS.ANNOUNCEMENTS
      );
    }
  }

  back() {
    this._location.back();
  }

  setFileUploaded() {
    this.fileUploaded = true;
  }
}
