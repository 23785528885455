<div class="pop_alert_box" [ngClass]="{
  'success': data.type == 'success',
  'warning': data.type == 'warning',
  'error': data.type == 'error'}">
  <span class="pop_alert_box--label">{{data.text}}</span>
  <img [src]="
  data.type == 'success' ? 'assets/alert-success.svg' :
  data.type == 'warning' ? 'assets/alert-warning.svg' :
  'assets/alert-error.svg'
  " alt="">
</div>
