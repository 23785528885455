import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

import { DailyUserAnalysisComponent } from './daily-user-analysis/daily-user-analysis.component';
import { PORTAL_TYPES } from '../../enums/portal-types.enum';
import { PortalFilterCard } from '../../models/portal-filter-card';
import { BarChartComponent } from '../bar-chart/bar-chart.component';
import { PORTAL_FILTER_CARD_TITLES } from '../filter-card/enums/filter-card-titles.enum';
import { FilterCardComponent } from '../filter-card/filter-card.component';
import { FilterCardsService } from '../filter-card/services/filter-cards.service';
import { HeaderBoxTableComponent } from '../header-box-table/header-box-table.component';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { SingleDoughnutChartComponent } from '../single-doughnut-card/single-doughnut-card.component';
import { SidebarOptionTitleBandComponent } from '../title-band/sidebar-option-title-band.component';
import { TopFiveComponent } from '../top-five/top-five.component';
import { Router } from '@angular/router';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { SIDEBAR_OPTIONS_URLS } from '../../enums/sidebar-option-urls.enum';
import { DynamicTableService } from '../dynamic-table/services/dynamic-table.service';
import { HeaderTableSettings } from '../header-box-table/header-box-table.model';
import { exportExcelTable } from '../../utils';
import { Rj190Service } from '../../services/190rj/rj190.service';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-general-panel',
  standalone: true,
  imports: [
    CommonModule,
    SidebarOptionTitleBandComponent,
    FilterCardComponent,
    LineChartComponent,
    SingleDoughnutChartComponent,
    BarChartComponent,
    DailyUserAnalysisComponent,
    TopFiveComponent,
    HeaderBoxTableComponent,
  ],
  templateUrl: './general-panel.component.html',
  styleUrl: './general-panel.component.scss',
})
export class GeneralPanelComponent implements OnInit, OnDestroy {
  portalCards: Array<PortalFilterCard> = [];
  portalCardsSubscription: Subscription | null = null;
  myEventSubscribeCurrentPortal: Subscription | null = null;
  selectedPortalFilterCard: PortalFilterCard | null = null;
  painelData: any[];
  portalCardsTitle = PORTAL_FILTER_CARD_TITLES;
  portalTypes = PORTAL_TYPE_URLS;
  tableConfig: any = {};
  topFive: Array<any> = [];
  topFiveData: any = {};

  constructor(
    private _portalFilterCardsService: FilterCardsService,
    public router: Router,
    private _tableService: DynamicTableService,
    private rj190Service: Rj190Service,
    private loadingService: LoadingService,
  ) { }

  async ngOnInit() {
    this.loadingService.present();
    this.emitNewPortalFilterCards();
    this.listenToNewPortalCards();
    this.listenToChangesOnSelectedPortalFilterCard();
    await this.getNumericDatas();
    this.loadingService.dismiss();
  };

  ngOnDestroy() {
    if (this.portalCardsSubscription) this.portalCardsSubscription?.unsubscribe();
    if (this.myEventSubscribeCurrentPortal) this.myEventSubscribeCurrentPortal?.unsubscribe();
  }

  getNumericDatas(): Promise<boolean> {
    return new Promise((resolve) => {
      this.rj190Service.getTotalStatics().subscribe({
        next: (response: any) => {
          this.portalCards.forEach(card => {
            if (card.title == 'Usuários') card.value = response.totalUsuarios;
            if (card.title == 'Emergências') card.value = response.totalEmergencias;
            if (card.title == 'Denúncias') card.value = response.totalDenuncias;
            if (card.title == 'Desaparecidos') card.value = response.totalDesaparecidos;
          });
        },
        error: (err: any) => {
          console.log(err);
        }
      }).add(() => resolve(true));
    })
  };

  topEmergencyData(): Promise<boolean> {
    this.topFive = [];
    return new Promise((resolve) => {
      this.rj190Service.emergencyTops().subscribe({
        next: (response) => {
          response.forEach((oneTop: any, index: any) => {
            oneTop.title = oneTop.tipoChamado;
            oneTop.value = oneTop.total;
            oneTop.color = index % 2 == 0 ? '#895BF1' : '#FEBF0F';
            delete oneTop.tipoChamado;
            delete oneTop.total;
            this.topFive.push(oneTop);
          });
          var emegencyCard = this.portalCards.filter(function (card) {
            return card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES;
          })[0];
          this.topFiveData.total = emegencyCard.value;
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };

  topDenouncementData(): Promise<boolean> {
    this.topFive = [];
    return new Promise((resolve) => {
      this.rj190Service.denouncementTops().subscribe({
        next: (response) => {
          response.forEach((oneTop: any, index: any) => {
            oneTop.title = oneTop.tipoDenuncia;
            oneTop.value = oneTop.total;
            oneTop.color = index % 2 == 0 ? '#0090FF' : '#FEBF0F';
            delete oneTop.tipoDenuncia;
            delete oneTop.total;
            this.topFive.push(oneTop);
          });
          var denouncementCard = this.portalCards.filter(function (card) {
            return card.title === PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS;
          })[0];
          this.topFiveData.total = denouncementCard.value;
        },
        error: (err) => {
          console.log(err);
        },
      }).add(() => resolve(true));
    })
  };

  async clickCard() {
    this.loadingService.present();
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_DENOUNCEMENTS) {
      await this.topDenouncementData();
      await this.getConfigTable();
    };
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_EMERGENCIES) {
      await this.topEmergencyData();
      await this.getConfigTable();
    };
    if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_MISSING) {
      // await this.topDenouncementData();
      await this.getConfigTable();
    };
    this.loadingService.dismiss();
  }

  getConfigTable(): Promise<boolean> {
    return new Promise(async resolve => {
      if (this.selectedPortalFilterCard?.title == this.portalCardsTitle.RJ_190_USERS) return resolve(false);
      this.tableConfig = await this.getData();
      this.painelData = this.tableConfig.tableBody;
      resolve(true);
    });
  }

  emitNewPortalFilterCards() {
    this._portalFilterCardsService.emitNewPortalFilterCards(
      PORTAL_TYPES.RJ_190
    );
  }

  listenToNewPortalCards() {
    this.portalCardsSubscription =
      this._portalFilterCardsService.portalFilterCard$.subscribe(
        (portalCards: Array<PortalFilterCard>) => {
          this.portalCards = portalCards;
        }
      );
  }

  listenToChangesOnSelectedPortalFilterCard() {
    this.myEventSubscribeCurrentPortal = this._portalFilterCardsService.currentPortalFilterCard$.subscribe(
      (selectedPortalFilterCard: PortalFilterCard | null) => {
        if (selectedPortalFilterCard) {
          this.selectedPortalFilterCard = selectedPortalFilterCard;
          this.topFiveData.title = selectedPortalFilterCard.title;
          this.portalCards.forEach((portalCard: PortalFilterCard) => {
            portalCard.active = false;
            if (portalCard.id === selectedPortalFilterCard.id) {
              portalCard.active = true;
            }
          });
        };
      }
    );
  }

  public searchAndFilterItens(event: any) {
    let newArray: any = [];
    this.painelData.forEach((elementArray) => {
      if (event?.searchValue && event?.filterValue) {
        if (this.filterDepedingCard(event.filterValue, elementArray)) {
          if (this.validateObject(elementArray, event.searchValue)) {
            newArray.push(elementArray);
          }
        }
      } else {
        if (event?.searchValue) {
          if (this.validateObject(elementArray, event.searchValue)) {
            newArray.push(elementArray);
          }
        } else {
          if (this.filterDepedingCard(event.filterValue, elementArray))
            newArray.push(elementArray);
        }
      }
    });
    this.tableConfig.tableBody = newArray;
  }

  public filterDepedingCard(value: string, object: any): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES:
        if (value.toLowerCase().includes(object.type.toLowerCase()))
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS:
        if (value.toLowerCase().includes(object.type.toLowerCase()))
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING:
        if (value.toLowerCase().includes(object.type.toLowerCase()))
          return true;
        return false;


      default:
        return false;
    }
  }

  public validateObject(object: any, textValue: string): boolean {
    switch (this.selectedPortalFilterCard?.title) {
      case PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES:
        if (
          object.name.toLowerCase().includes(textValue) ||
          object.cpf.toString().includes(textValue) ||
          object.measure.toLowerCase().includes(textValue) ||
          object.dueDate.toString().toLowerCase().includes(textValue) ||
          object.victim.toLowerCase().includes(textValue)
        )
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS:
        if (
          object.name.toLowerCase().includes(textValue) ||
          object.date.toString().includes(textValue) ||
          object.hour.toLowerCase().includes(textValue) ||
          object.amount.toString().toLowerCase().includes(textValue) ||
          object.resume.toLowerCase().includes(textValue)
        )
          return true;
        return false;

      case PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING:
        if (
          object.name.toLowerCase().includes(textValue) ||
          object.cpf.toString().includes(textValue) ||
          object.measure.toLowerCase().includes(textValue) ||
          object.dueDate.toString().toLowerCase().includes(textValue) ||
          object.victim.toLowerCase().includes(textValue)
        )
          return true;
        return false;

      default:
        return false;
    }
  }

  public clickExcelButton() {
    exportExcelTable(this.painelData);
  }

  public clearSearchTable() {
    this.tableConfig.tableBody = this.painelData;
  }

  private async getData() {
    return await this._tableService.getObjectConfigTable(this.router.url, SIDEBAR_OPTIONS_URLS.GENERAL_PANEL, this.selectedPortalFilterCard);
  }

  shouldShowTopFive() {
    return (
      (this.selectedPortalFilterCard?.selectedPortal.type ===
        PORTAL_TYPES.RJ_190 &&
        this.selectedPortalFilterCard?.title ===
        PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) ||
      (this.selectedPortalFilterCard?.selectedPortal.type ===
        PORTAL_TYPES.RJ_190 &&
        this.selectedPortalFilterCard?.title ===
        PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS)
    );
  }
}
