import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-image-upload',
  standalone: true,
  imports: [],
  templateUrl: './image-upload.component.html',
  styleUrl: './image-upload.component.scss',
})
export class ImageUploadComponent implements AfterViewInit {
  @Output() fileSent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('fileUpload')
  private fileUpload: ElementRef;

  globalInstance: any;
  constructor(private _renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.globalInstance = this._renderer.listen(
      this.fileUpload.nativeElement,
      'change',
      (e: any) => {
        this.fileSelectHandler(e);
      }
    );
  }

  fileSelectHandler(e: any) {
    var files = e.target.files || e.dataTransfer.files;
    for (var i = 0, f; (f = files[i]); i++) {
      this.parseFile(f);
      this.uploadFile(f);
    }
  }

  uploadFile(file: any) {
    var xhr = new XMLHttpRequest(),
      fileInput = document.getElementById('class-roster-file'),
      fileSizeLimit = 1024;
    if (xhr.upload) {
      if (file.size <= fileSizeLimit * 1024 * 1024) {
        xhr.onreadystatechange = function (e) {
          if (xhr.readyState == 4) {
          }
        };

        const formUploadForm = document.getElementById(
          'file-upload-form'
        ) as any;
        xhr.open('POST', formUploadForm.action, true);
        xhr.setRequestHeader('X-File-Name', file.name);
        xhr.setRequestHeader('X-File-Size', file.size);
        xhr.setRequestHeader('Content-Type', 'multipart/form-data');
        xhr.send(file);
        this.fileSent.emit(true);
      }
    }
  }

  parseFile(file: any) {
    var imageName = file.name;

    var isGood = /\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
    if (isGood) {
      document.getElementById('start')?.classList.add('hidden');
      document.getElementById('response')?.classList.remove('hidden');
      document.getElementById('notimage')?.classList.add('hidden');
      const fileImage = document.getElementById(
        'file-image'
      ) as HTMLImageElement;
      fileImage?.classList.remove('hidden');
      fileImage.src = URL.createObjectURL(file);
    } else {
      document.getElementById('file-image')?.classList.add('hidden');
      document.getElementById('notimage')?.classList.remove('hidden');
      document.getElementById('start')?.classList.remove('hidden');
      document.getElementById('response')?.classList.add('hidden');

      const formUploadForm = document.getElementById('file-upload-form') as any;
      formUploadForm.reset();
    }
  }
}
