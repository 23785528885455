import { Component, OnInit } from '@angular/core';
import { ButtonConfig } from '../../../shared/components/dynamic-button/dynamic-button.model';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { DynamicTableService } from '../../../shared/components/dynamic-table/services/dynamic-table.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SIDEBAR_OPTIONS_URLS } from '../../../shared/enums/sidebar-option-urls.enum';
import { EXTRAS_PAGES_URLS } from '../../../shared/enums/extras-pages-urls.enum';


@Component({
  selector: 'app-users-page190rj',
  standalone: true,
  imports: [HeaderBoxTableComponent, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './users-page190rj.component.html',
  styleUrl: './users-page190rj.component.scss'
})
export class UsersPage190rjComponent implements OnInit {
  usersData: any;
  configButtonRegister: ButtonConfig = { label: 'Cadastrar', imgSrc: "assets/icon_more.svg", handler: () => this.registerUser(), customColor: { backgroundColor: "#60B863" } };
  tableConfig: any = {};
  showTable: boolean = false;

  constructor(
    private tableService: DynamicTableService,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  async ngOnInit() {
    this.tableConfig = await this.getConfigTable();
    this.usersData = this.tableConfig.tableBody;
    this.showTable = true;
  }

  getConfigTable(): Promise<any> {
    return new Promise(resolve => {
      this.getData().subscribe(
        {
          next: (response) => {
            resolve(response);
          },
          error: () => {
            console.log('error api')
          }
        }
      )
    })
  }


  public searchAndFilterItens(event: any) {
    let newArray: any = [];
    this.usersData.forEach((user: any) => {
      if (event?.searchValue && event?.filterValue) {
        if (user.profile.toLowerCase().includes(event.filterValue)) {
          if (this.validateObject(user, event.searchValue)) {
            newArray.push(user);
          }
        }
      } else {
        if (event?.searchValue) {
          if (this.validateObject(user, event.searchValue)) {
            newArray.push(user);
          }
        } else {
          if (user.profile.toLowerCase().includes(event.filterValue)) newArray.push(user);
        }
      }
    });
    this.tableConfig.tableBody = newArray;
  }

  public validateObject(object: any, textValue: string): boolean {
    if (
      object.nome.toLowerCase().includes(textValue) ||
      object.cpf.toString().includes(textValue) ||
      object.email.toLowerCase().includes(textValue) ||
      object.organ.toLowerCase().includes(textValue) ||
      object.phone.toString().includes(textValue)) return true;
    return false;
  }

  public clearSearchTable() {
    this.tableConfig.tableBody = this.usersData;
  }

  public registerUser() {
    this.router.navigate([EXTRAS_PAGES_URLS.NEW_USER], { relativeTo: this.route });
  }

  private getData(): Observable<any> {
    // return this.tableService.getObjectConfigTable(this.router.url, SIDEBAR_OPTIONS_URLS.USERS);
    return of({});
  }
}
