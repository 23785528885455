import { AbstractControl } from "@angular/forms";
import { PORTAL_TYPE_URLS } from "./enums/portal-types-urls.enum";
import * as XLSX from 'xlsx';

export const cpfMask = function (v: any) {
  v = v.toString();
  v = v.replace(/\D/g, "")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return v
}

export const phoneMask = function (value: any) {
  if (!value) return ""
  value = value.toString();
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, "($1) $2")
  value = value.replace(/(\d)(\d{4})$/, "$1-$2")
  return value
}

export const getPortalTypeFromLink = function (link: string) {
  let portal: string = "";
  if (link.includes(PORTAL_TYPE_URLS.REDE_MULHER)) portal = PORTAL_TYPE_URLS.REDE_MULHER;
  else if (link.includes(PORTAL_TYPE_URLS.RJ_190)) portal = PORTAL_TYPE_URLS.RJ_190;
  else if (link.includes(PORTAL_TYPE_URLS.REDE_ESCOLA)) portal = PORTAL_TYPE_URLS.REDE_ESCOLA;
  return portal;
}

export const ValidateCpf = function (controle: AbstractControl) {
  const cpf = controle.value;

  let soma: number = 0;
  let resto: number;
  let valido: boolean;

  const regex = new RegExp('[0-9]{11}');

  if (
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999' ||
    !regex.test(cpf)
  )
    valido = false;
  else {
    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) valido = false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) valido = false;
    valido = true;
  }

  if (valido) return null;

  return { cpfInvalido: true };
}

export const generateLoremText = function () {
  let text = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
  return text;
}

export const exportExcelTable = function (array: any) {
  let newArray: any = [];
  for (let index = 0; index < array.length; index++) {
    if (array[index]?.type) {
      let otherElement = Object.assign({ Tipo: array[index].type }, array[index]);
      delete otherElement.type;
      newArray.push(otherElement)
    } else newArray.push(array[index]);
  };
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newArray);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `table_export_${new Date().getTime()}.xlsx`);
};

export const isTokenExpired = (token: any) => Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000;
