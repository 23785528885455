import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';
import { IRestApiCall } from './rest-api-call';

const TIMEOUT: number = 30000;

@Injectable({
  providedIn: 'root',
})
export class RestApiService {

  constructor(
    public http: HttpClient
  ) { }

  get(call: IRestApiCall): Observable<any> {
    let endpoint = this.buildRestEndpoint(call);
    let options = this.buildRequestOptions(call);

    return this.http.get(endpoint, options).pipe(timeout(TIMEOUT));
  }

  post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.post(endpoint, body, reqOpts).pipe(timeout(TIMEOUT));
  }

  put(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.put(endpoint, body, reqOpts).pipe(timeout(TIMEOUT));
  }

  delete(endpoint: string, reqOpts?: any): Observable<any> {
    return this.http
      .request('DELETE', endpoint, reqOpts)
      .pipe(timeout(TIMEOUT));
  }

  patch(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.patch(endpoint, body, reqOpts).pipe(timeout(TIMEOUT));
  }

  private buildRequestOptions(call: IRestApiCall): any {
    let headers: HttpHeaders;
    let queryParams: HttpParams;
    let requestOptions: any;

    if (call?.headers) {
      headers = new HttpHeaders();
      Object.keys(call.headers).forEach((headerName) => {
        headers = headers.append(headerName, call.headers[headerName]);
      });
      requestOptions = {
        ...requestOptions,
        headers: headers,
      };
    }

    if (call?.queryParams) {
      queryParams = new HttpParams();
      Object.keys(call.queryParams).forEach((paramName) => {
        queryParams = queryParams.append(
          paramName,
          call.queryParams[paramName]
        );
      });
      requestOptions = {
        ...requestOptions,
        params: queryParams,
      };
    }

    if (call.responseType) {
      requestOptions = {
        ...requestOptions,
        responseType: call.responseType,
      };
    }

    if (call.observe) {
      requestOptions = {
        ...requestOptions,
        observe: call.observe,
      };
    }

    if (call.responseType) {
      requestOptions = {
        ...requestOptions,
        responseType: call.responseType,
      };
    }
    return requestOptions;
  }

  private buildRestEndpoint(call: IRestApiCall): string {
    let endpoint = call.baseUrl + call.endpoint;
    let pathParams = call.pathParams;

    if (pathParams) {
      Object.keys(pathParams).forEach((nomeParam) => {
        let regex = new RegExp('{' + nomeParam + '}', 'g');
        endpoint = endpoint.replace(regex, pathParams[nomeParam]);
      });
    }
    return endpoint;
  }
}
