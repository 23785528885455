import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DynamicTableComponent } from '../dynamic-table/dynamic-table.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderTableSettings } from './header-box-table.model';
import { DynamicButtonComponent } from '../dynamic-button/dynamic-button.component';
import { ButtonConfig } from '../dynamic-button/dynamic-button.model';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-box-table',
  standalone: true,
  imports: [CommonModule, DynamicTableComponent, FormsModule, DynamicButtonComponent],
  templateUrl: './header-box-table.component.html',
  styleUrl: './header-box-table.component.scss'
})
export class HeaderBoxTableComponent implements OnInit {
  @Input() headerBoxTableSettings: HeaderTableSettings;
  @Output() public searchClick = new EventEmitter<any>();
  @Output() public clearSearchClick = new EventEmitter<any>();
  @Output() public excelButtonClick = new EventEmitter<any>();
  @ViewChild(DynamicTableComponent) childView!: DynamicTableComponent;
  tableSettings: any;
  searchTextValue: string;
  filterValue: any;
  showButtonClearSearch: boolean = false;
  configButtonSearch: ButtonConfig = { label: 'Pesquisar', handler: () => this.searchInComponent() };
  configButtonClearSearch: ButtonConfig = { label: 'Limpar', handler: () => this.clearSearch(), customColor: { backgroundColor: "#f7a01d" } };
  urlActive: string;
  portalTypes = PORTAL_TYPE_URLS;

  constructor(private router: Router) { }

  ngOnInit(): void {
    const arrayUnderConstruction = this.tableSettings || {};
    arrayUnderConstruction.titlesTables = this.headerBoxTableSettings.titlesTables;
    arrayUnderConstruction.tableBody = this.headerBoxTableSettings.tableBody;
    arrayUnderConstruction.itensPerPage = this.headerBoxTableSettings.itensPerPage;
    arrayUnderConstruction.showButton = this.headerBoxTableSettings.showButton ? this.headerBoxTableSettings.showButton : false;
    arrayUnderConstruction.buttonEndTable = this.headerBoxTableSettings.buttonTable;
    this.urlActive = this.router.url;
    this.tableSettings = arrayUnderConstruction;
  }


  ngOnChanges(changes: SimpleChanges) {
    let newArrayAfterFilter = changes['headerBoxTableSettings'].currentValue;
    this.tableSettings = newArrayAfterFilter;
  }

  public searchInComponent() {
    if (this.isValidText(this.searchTextValue)) {
      this.searchClick.emit({ searchValue: this.searchTextValue.toLowerCase(), filterValue: this.filterValue ? this.filterValue.toLowerCase() : null });
      this.showButtonClearSearch = true;
    } else {
      if (this.isValidText(this.filterValue)) {
        this.showButtonClearSearch = true;
        return this.searchClick.emit({ filterValue: this.filterValue.toLowerCase() })
      };
      return alert('nenhum dado para pesquisar');
    }
  }

  public clearSearch() {
    this.showButtonClearSearch = false;
    this.searchTextValue = "";
    this.filterValue = undefined;
    this.clearSearchClick.emit();
  }

  public isValidText(text: string): boolean {
    if (text != "" && text != null && text != undefined) return true;
    return false;
  }
}
