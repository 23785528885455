import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { rj190Interceptor } from './shared/interceptors/190rj/rj190.interceptor';
import { redeMulherInterceptor } from './shared/interceptors/rede-mulher/rede-mulher.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideAnimationsAsync(), provideHttpClient(withInterceptors([
    rj190Interceptor, redeMulherInterceptor
  ])),]
};
