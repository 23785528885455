import { Injectable } from '@angular/core';
import { PORTAL_TYPE_URLS } from '../../../enums/portal-types-urls.enum';
import { SIDEBAR_OPTIONS_URLS } from '../../../enums/sidebar-option-urls.enum';
import { Observable, firstValueFrom, of } from 'rxjs';
import { HeaderTableSettings } from '../../header-box-table/header-box-table.model';
import { PORTAL_FILTER_CARD_TITLES } from '../../filter-card/enums/filter-card-titles.enum';
import { MockDataTable } from './dynamic-table.mocks';
import { Rj190Service } from '../../../services/190rj/rj190.service';
import { RedeMulherService } from '../../../services/rede-mulher/rede-mulher.service';
@Injectable({
  providedIn: 'root'
})
export class DynamicTableService {

  constructor(
    private rj190Service: Rj190Service,
    private redeMulherService: RedeMulherService
  ) { }


  public async getObjectConfigTable(portal: string, page: string, card?: any) {
    if (portal.includes(PORTAL_TYPE_URLS.RJ_190)) return await this.getSettingsTableRj190(page, card);
    else if (portal.includes(PORTAL_TYPE_URLS.REDE_MULHER)) return await this.getSettingsTableMulher(page, card);
    else if (portal.includes(PORTAL_TYPE_URLS.REDE_ESCOLA)) return this.getSettingsTableEscola(page, card);
    return {};
  }

  public async getSettingsTableRj190(page: string, clickedCard: any) {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let responseReported = await this.getTableRj190(page, clickedCard);
      if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.showTextBox = false;
        config.showFilterBox = true;
        config.showExcelButton = true;
        config.optionsFilter = [
          { name: 'Tipo', value: '', default: true },
        ];
        responseReported.forEach((element: any) => {
          let object = { name: element.type, value: element.type };
          if (!config.optionsFilter?.includes(object)) config.optionsFilter?.push(object);
        });
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.showTextBox = false;
        config.showFilterBox = true;
        config.showExcelButton = true;
        config.optionsFilter = [
          { name: 'Tipo', value: '', default: true },
        ];
        responseReported.forEach((element: any) => {
          let object = { name: element.type, value: element.type };
          if (!config.optionsFilter?.includes(object)) config.optionsFilter?.push(object);
        });
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.showTextBox = false;
        config.showFilterBox = true;
        config.showExcelButton = true;
        config.optionsFilter = [
          { name: 'Tipo', value: '', default: true },
        ];
        responseReported.forEach((element: any) => {
          let object = { name: element.type, value: element.type };
          if (!config.optionsFilter?.includes(object)) config.optionsFilter?.push(object);
        });
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = responseReported;
      };
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      let responseUsers = await this.getTableRj190(page, clickedCard);
      config.headerTitle = "Usuários";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail', 'Órgão', 'Perfil'];
      config.itensPerPage = 10;
      config.optionsFilter = [
        { name: "Perfil", value: "", default: true },
        { name: "Administrador", value: "admin" },
        { name: "Suporte", value: "sup" },
        { name: "Observador", value: "obs" },
      ];
      config.showFilterBox = true;
      config.tableBody = responseUsers;
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      let responseEmergency = await this.getTableRj190(page, clickedCard);
      config.headerTitle = "Emergências";
      config.itensPerPage = 10;
      config.showTextBox = false;
      config.showFilterBox = true;
      config.optionsFilter = [
        { name: "Status", value: "", default: true },
        { name: "Atendida", value: "Atendida" },
        { name: "Pendente", value: "Pendente" },
        { name: "Negada", value: "Negada" },
      ];
      config.titlesTables = ['Data', 'Hora', 'Tipo', 'Mike 190', 'Status', 'Satisfação'];
      config.tableBody = responseEmergency;
    } else if (page == SIDEBAR_OPTIONS_URLS.REPORTED) {
      let responseReported = await this.getTableRj190(page, clickedCard);
      console.log(responseReported);
      if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por usuário ou palavra';
        config.showFilterBox = false;
        config.titlesTables = ['Autor',
          'Data',
          'Horário',
          'Quantidade',
          'Resumo do Post',
          'Status',
          'Ver Publicação',];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por usuário ou palavra';
        config.showFilterBox = false;
        config.titlesTables = [
          'Autor',
          'Data',
          'Horário',
          'Quantidade',
          'Resumo do Post',
          'Ver Publicação',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por usuário ou palavra';
        config.showFilterBox = false;
        config.titlesTables = [
          'Autor',
          'CPF',
          'Telefone',
          'E-mail',
          'Data de Bloqueio',
          'Ver Publicação',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por usuário ou palavra';
        config.showFilterBox = false;
        config.titlesTables = [
          'Autor',
          'CPF',
          'Telefone',
          'E-mail',
          'Data de Bloqueio',
          'Ver Publicação',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;
      };
    };
    return config;
  }

  public async getSettingsTableMulher(page: string, clickedCard: any) {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let responseReported = await this.getTableMulher(page, clickedCard);
      if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
        config.headerTitle = 'Todos os ' + clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquise por nome';
        config.showFilterBox = true;
        config.optionsFilter = [
          { name: 'Status', value: '', default: true },
          { name: 'Ativo', value: 'true' },
          { name: 'Inativo', value: 'false' },
        ];
        config.titlesTables = [
          'Nome',
          'CPF',
          'Telefone',
          'E-mail',
          'Status',
          'Ver Perfil',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 5;
        config.showTextBox = false;
        config.showFilterBox = true;
        config.optionsFilter = [
          { name: 'Tipo', value: '', default: true },
        ];
        responseReported.forEach((element: any) => {
          let object = { name: element.type, value: element.type };
          if (!config.optionsFilter?.includes(object)) config.optionsFilter?.push(object);
        });
        config.titlesTables = [
          'Tipo',
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Total'];
        config.showButton = false;
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED) {
        config.headerTitle = clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquisar por nome ou palavra';
        config.showFilterBox = false;
        // config.optionsFilter = [
        //   { name: "Todo o periodo", value: "", default: true },
        //   { name: "teste", value: "teste" },
        //   { name: "teste", value: "teste" },
        //   { name: "teste", value: "teste" },
        // ];
        config.titlesTables = [
          'Autor',
          'Data',
          'Horário',
          'Qt. de Reports',
          'Resumo do Post',
          'Ver Perfil',
        ];
        config.showButton = true;
        config.buttonTable = { label: 'Visualizar' };
        config.tableBody = responseReported;

      } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
        config.headerTitle = 'Todos os' + ' ' + clickedCard.title;
        config.itensPerPage = 10;
        config.showTextBox = true;
        config.titleSearch = 'Pesquisar por nome do agressor';
        config.showFilterBox = false;
        config.titlesTables = [
          'Agressor',
          'CPF',
          'Medida Protetiva',
          'Data do Vencimento',
          'Vítima',
        ];
        config.showButton = false;
        config.tableBody = responseReported;
      };
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      let responseUsers = await this.getTableMulher(page);
      config.headerTitle = "Usuários";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Nome', 'CPF', 'Telefone', 'E-mail', 'Órgão', 'Perfil'];
      config.itensPerPage = 10;
      config.showTextBox = false;
      config.showFilterBox = true;
      config.optionsFilter = [
        { name: "Perfil", value: "", default: true },
        { name: "Administrador", value: "admin" },
        { name: "Suporte", value: "sup" },
        { name: "Observador", value: "obs" },
      ];
      config.showButton = false;
      config.tableBody = responseUsers;
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      let responseUsers = await this.getTableMulher(page);
      config.headerTitle = "Emergências";
      config.titleSearch = "Pesquisar por nome";
      config.titlesTables = ['Protocolo', 'Data', 'Hora', 'Tipo', 'Vítima', 'Status', 'Classificação'];
      config.itensPerPage = 10;
      config.showTextBox = false;
      config.showFilterBox = true;
      config.optionsFilter = [
        { name: "Tipo", value: "", default: true },
        { name: "Violência Sexual", value: "Violência Sexual" },
        { name: "Violência Verbal", value: "Violência Verbal" },
        { name: "Violência Psicologica", value: "Violência Psicologica" },
      ];
      config.showButton = false;
      config.tableBody = responseUsers;
    };
    return config;
  }

  public getSettingsTableEscola(page: string, clickedCard: any): Observable<any> {
    let config: HeaderTableSettings = {};
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      this.getTableEscola(page, clickedCard).subscribe({
        next: (responseReported) => {
          if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 10;
            config.showTextBox = true;
            config.titleSearch = 'Pesquise por nome';
            config.showFilterBox = true;
            config.optionsFilter = [
              { name: 'Tipo', value: '', default: true },
              { name: 'Pública', value: 'Pública' },
              { name: 'Municipal', value: 'Municipal' },
              { name: 'Estadual', value: 'Estadual' },
              { name: 'Federal', value: 'Federal' },
              { name: 'Privada', value: 'Privada' },
            ];
            config.titlesTables = [
              'Nome da escola',
              'Tipo',
              'Endereço',
              'Telefone',
              'E-mail',
            ];
            config.showButton = false;
            config.tableBody = responseReported;

          } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 4;
            config.showTextBox = false;
            config.showFilterBox = true;
            config.showExcelButton = true;
            config.optionsFilter = [
              { name: 'Todo o período', value: '', default: true },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
            ];
            config.titlesTables = [
              'Nome da escola',
              'Nome do usuário',
              'Telefone',
              'Data da denúncia',
              'Ver denúncia'
            ];
            config.showButton = true;
            config.buttonTable = { label: 'Visualizar' };
            config.tableBody = responseReported;

          } else if (clickedCard.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES) {
            config.headerTitle = clickedCard.title;
            config.itensPerPage = 5;
            config.showTextBox = false;
            config.showFilterBox = true;
            config.showExcelButton = true;
            config.optionsFilter = [
              { name: 'Todo o período', value: '', default: true },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
              { name: 'Teste', value: 'teste' },
            ];
            config.titlesTables = [
              'Tipo',
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
              'Total'];
            config.showButton = false;
            config.tableBody = responseReported;

          };
        },
        error: () => {
          config = {};
        },
      });
    } else if (SIDEBAR_OPTIONS_URLS.EMERGENCY) {
    };
    return of(config);
  }

  private async getTableRj190(page: string, cardOption?: any) {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_EMERGENCIES) {
        await firstValueFrom(this.rj190Service.emengercyTable()).then((resService) => {
          response = resService;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_DENOUNCEMENTS) {
        await firstValueFrom(this.rj190Service.denouncementTable()).then((resService) => {
          response = resService;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING) {
        await firstValueFrom(this.rj190Service.missingTable()).then((resService) => {
          response = resService;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
      };
      if (response) {
        let newResponse: any = [];
        response.forEach((element: any) => {
          let newObject = {
            type: element?.tipoChamado ? element.tipoChamado : element.tipoDenuncia ? element.tipoDenuncia : element.tipo,
            jan: element.Jan ? element.Jan : 0,
            fev: element.Fev ? element.Fev : 0,
            mar: element.Mar ? element.Mar : 0,
            abr: element.Abr ? element.Abr : 0,
            mai: element.Mai ? element.Mai : 0,
            jun: element.Jun ? element.Jun : 0,
            jul: element.Jul ? element.Jul : 0,
            ago: element.Ago ? element.Ago : 0,
            set: element.Set ? element.Set : 0,
            out: element.Out ? element.Out : 0,
            nov: element.Nov ? element.Nov : 0,
            dez: element.Dez ? element.Dez : 0,
            total: element.Jan +
              element.Fev +
              element.Mar +
              element.Abr +
              element.Mai +
              element.Jun +
              element.Jul +
              element.Ago +
              element.Set +
              element.Out +
              element.Nov +
              element.Dez
          };
          newResponse.push(newObject);
        });
        return newResponse;
      } else return {};
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      return MockDataTable.RJ190.USERS;
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      return MockDataTable.RJ190.EMERGENCY;
    } else if (page == SIDEBAR_OPTIONS_URLS.REPORTED) {
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_REPORTS) {
        return MockDataTable.RJ190.REPORTED.REPORTS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_OPEN_STATUS) {
        return MockDataTable.RJ190.REPORTED.OPEN_STATUS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_BLOCKED) {
        return MockDataTable.RJ190.REPORTED.BLOCKED;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.RJ_190_CLOSED_STATUS) {
        return MockDataTable.RJ190.REPORTED.CLOSED_STATUS;
      };
    };

    return {};
  }

  private getTableEscola(page: string, cardOption?: any): Observable<any> {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_SCHOOLS) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.SCHOOLS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.DENOUNCEMENTS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES) {
        response = MockDataTable.REDE_ESCOLA.GENERAL_PANEL.EMERGENCIES;
        response.forEach((element: any) => {
          element.total =
            element.jan +
            element.fev +
            element.mar +
            element.abr +
            element.mai +
            element.jun +
            element.jul +
            element.ago +
            element.set +
            element.out +
            element.nov +
            element.dez;
        });
      };
      return of(response);
    }
    return of({});
  }

  private async getTableMulher(page: string, cardOption?: any) {
    if (page == SIDEBAR_OPTIONS_URLS.GENERAL_PANEL) {
      let response: any;
      if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS) {
        await firstValueFrom(this.redeMulherService.usersTable()).then((resService) => {
          response = resService.Results;
          response.forEach((element: any) => {
            element.Nome = element.Nome ? element.Nome : 'Sem nome';
            element.CPF = element.CPF ? element.CPF : 'Sem CPF';
            element.Email = element.Email ? element.Email : 'Sem email';
            element.Telefone = element.Telefone ? element.Telefone : 'Sem telefone';
            element.Status = element.Status ? 'Ativo' : 'Inativo';
          });
        }).catch((err) => {
          response = [];
          console.log(err)
        });
        // response = MockDataTable.REDE_MULHER.GENERAL_PANEL.USERS;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_EMERGENCIES) {
        await firstValueFrom(this.redeMulherService.emengercyTable()).then((resService) => {
          response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
        if (response) {
          let newResponse: any = [];
          response.forEach((element: any) => {
            let newObject = {
              type: element?.NM_TipoOcorrencia ? element?.NM_TipoOcorrencia : element?.NM_Tipo,
              jan: element[1] ? element[1] : 0,
              fev: element[2] ? element[2] : 0,
              mar: element[3] ? element[3] : 0,
              abr: element[4] ? element[4] : 0,
              mai: element[5] ? element[5] : 0,
              jun: element[6] ? element[6] : 0,
              jul: element[7] ? element[7] : 0,
              ago: element[8] ? element[8] : 0,
              set: element[9] ? element[9] : 0,
              out: element[10] ? element[10] : 0,
              nov: element[11] ? element[11] : 0,
              dez: element[12] ? element[12] : 0,
              total: element[1] +
                element[2] +
                element[3] +
                element[4] +
                element[5] +
                element[6] +
                element[7] +
                element[8] +
                element[9] +
                element[10] +
                element[11] +
                element[12]
            };
            newResponse.push(newObject);
          });
          response = newResponse;
        };
        // response = MockDataTable.REDE_MULHER.GENERAL_PANEL.EMERGENCIES;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_REPORTED) {
        await firstValueFrom(this.redeMulherService.reportedTable()).then((resService) => {
          // response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
        response = MockDataTable.REDE_MULHER.GENERAL_PANEL.REPORTED;
      } else if (cardOption.title == PORTAL_FILTER_CARD_TITLES.REDE_MULHER_AGRESSOR) {
        await firstValueFrom(this.redeMulherService.agressorTable()).then((resService) => {
          response = resService.Results;
        }).catch((err) => {
          response = [];
          console.log(err)
        });
      };
      return response;
    } else if (page == SIDEBAR_OPTIONS_URLS.USERS) {
      return MockDataTable.REDE_MULHER.USERS
    } else if (page == SIDEBAR_OPTIONS_URLS.EMERGENCY) {
      return MockDataTable.REDE_MULHER.EMERGENCY
    }

    return of({});
  }
}
