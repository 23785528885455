import { Component, OnInit } from '@angular/core';
import { ButtonConfig } from '../../../shared/components/dynamic-button/dynamic-button.model';
import { HeaderBoxTableComponent } from '../../../shared/components/header-box-table/header-box-table.component';
import { SidebarOptionTitleBandComponent } from '../../../shared/components/title-band/sidebar-option-title-band.component';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { DynamicTableService } from '../../../shared/components/dynamic-table/services/dynamic-table.service';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { exportExcelTable } from '../../../shared/utils';


@Component({
  selector: 'app-emergency-page190rj',
  standalone: true,
  imports: [HeaderBoxTableComponent, SidebarOptionTitleBandComponent, DynamicButtonComponent],
  templateUrl: './emergency-page190rj.component.html',
  styleUrl: './emergency-page190rj.component.scss'
})
export class EmergencyPage190rjComponent implements OnInit {
  emergencysData: any[];
  configButtonRegister: ButtonConfig = { label: 'Excel', imgSrc: "assets/excel_ic.svg", handler: () => exportExcelTable(this.emergencysData), customColor: { backgroundColor: "#00954B" } };
  tableConfig: any = {};
  showTable: boolean = false;

  constructor(
    private tableService: DynamicTableService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.tableConfig = await this.getConfigTable();
    this.emergencysData = this.tableConfig.tableBody;
    this.showTable = true;
  }

  getConfigTable(): Promise<any> {
    return new Promise(resolve => {
      this.getData().subscribe(
        {
          next: (response) => {
            resolve(response);
          },
          error: () => {
            console.log('error api')
          }
        }
      )
    })
  }

  public searchAndFilterItens(event: any) {
    let newArray: any = [];
    this.emergencysData.forEach(emergency => {
      if (event?.searchValue && event?.filterValue) {
        if (this.filterDepedingCard(event.filterValue, emergency)) {
          if (this.validateObject(emergency, event.searchValue)) {
            newArray.push(emergency);
          }
        }
      } else {
        if (event?.searchValue) {
          if (this.validateObject(emergency, event.searchValue)) {
            newArray.push(emergency);
          }
        } else {
          if (this.filterDepedingCard(event.filterValue, emergency)) newArray.push(emergency);
        }
      }
    });
    this.tableConfig.tableBody = newArray;
  }

  public filterDepedingCard(value: string, object: any): boolean {
    if (value.toLowerCase().includes(object.status.toLowerCase())) return true;
    return false;
  }

  public validateObject(object: any, textValue: string): boolean {
    if (
      object.date.toString().toLowerCase().includes(textValue) ||
      object.hour.toString().includes(textValue) ||
      object.type.toString().toLowerCase().includes(textValue) ||
      object.mike.toString().toLowerCase().includes(textValue) ||
      object.status.toString().toLowerCase().includes(textValue) ||
      object.satisfaction.toString().toLowerCase().includes(textValue)) return true;
    return false;
  }

  public clearSearchTable() {
    this.tableConfig.tableBody = this.emergencysData;
  }

  private getData(): Observable<any> {
    // return this.tableService.getObjectConfigTable(this.router.url, SIDEBAR_OPTIONS_URLS.EMERGENCY);
    return of({});
  }
}
