import { Injectable } from '@angular/core';

import Chart from 'chart.js/auto';
import { PORTAL_TYPES } from '../../../enums/portal-types.enum';
import { PortalFilterCard } from '../../../models/portal-filter-card';
import { PORTAL_FILTER_CARD_TITLES } from '../../filter-card/enums/filter-card-titles.enum';
import { firstValueFrom, of } from 'rxjs';
import { DoughnutDataset } from '../models/doughnut-dataset';
import { Rj190Service } from '../../../services/190rj/rj190.service';

@Injectable({
  providedIn: 'root',
})
export class SingleDoughtnutCardService {
  chart: any;

  constructor(
    private rj190Service: Rj190Service
  ) { }

  async getChartData(portalType: PORTAL_TYPES, currentFilterCard: PortalFilterCard) {
    if (
      portalType === PORTAL_TYPES.RJ_190 &&
      currentFilterCard?.title === PORTAL_FILTER_CARD_TITLES.RJ_190_USERS
    ) {
      let cardLabels: any = [
        {
          id: 1,
          name: 'Ativo',
          backgroundColor: '#895BF1',
        },
        {
          id: 2,
          name: 'Inativo',
          backgroundColor: '#0090FF',
        },
      ];
      let dataset: any;
      await firstValueFrom(this.rj190Service.activeInativeUsers()).then((response) => {
        cardLabels[0].value = response.ativos;
        cardLabels[1].value = response.inativos;
        dataset = new DoughnutDataset([response.ativos, response.inativos], ['#895BF1', '#0090FF']);
      }).catch(() => cardLabels = []);
      return {
        cardLabels,
        dataset: dataset,
        cardTitle: 'Atividade',
      };
    } else if (
      portalType === PORTAL_TYPES.RJ_190 &&
      currentFilterCard?.title === PORTAL_FILTER_CARD_TITLES.RJ_190_MISSING
    ) {
      let cardLabels: any = [
        {
          id: 1,
          name: 'Desaparecidos',
          backgroundColor: '#FE6BBA',
        },
        {
          id: 2,
          name: 'Encontrados',
          backgroundColor: '#FEBF0F',
        },
      ];
      let dataset: any;
      await firstValueFrom(this.rj190Service.foungMissingTotal()).then((response) => {
        cardLabels[0].value = response[0].total;
        cardLabels[1].value = response[1].total;
        dataset = new DoughnutDataset([response[0].total, response[1].total], ['#FE6BBA', '#FEBF0F']);
      }).catch(() => cardLabels = []);
      return {
        cardLabels,
        dataset: dataset,
        cardTitle: 'Atividade',
      };
    } else if (
      portalType === PORTAL_TYPES.REDE_MULHER &&
      currentFilterCard?.title === PORTAL_FILTER_CARD_TITLES.REDE_MULHER_USERS
    ) {
      return {
        cardLabels: [
          {
            id: 1,
            name: 'iOS',
            value: 6000,
            backgroundColor: '#E8149A',
          },
          {
            id: 2,
            name: 'Android',
            value: 4000,
            backgroundColor: '#895BF1',
          },
        ],
        dataset: new DoughnutDataset([6000, 4000], ['#E8149A', '#895BF1']),
        cardTitle: 'Downloads',
      };
    } else if (
      portalType === PORTAL_TYPES.REDE_ESCOLA &&
      currentFilterCard?.title === PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_USERS
    ) {
      return {
        cardLabels: [
          {
            id: 1,
            name: 'iOS',
            value: 6000,
            backgroundColor: '#0090FF',
          },
          {
            id: 2,
            name: 'Android',
            value: 4000,
            backgroundColor: '#26C6DA',
          },
        ],
        dataset: new DoughnutDataset([6000, 4000], ['#0090FF', '#26C6DA']),
        cardTitle: 'Downloads'
      };
    } else if (
      portalType === PORTAL_TYPES.REDE_ESCOLA &&
      currentFilterCard?.title ===
      PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_DENOUNCEMENTS
    ) {
      return {
        cardLabels: [
          {
            id: 1,
            name: 'Meninas',
            value: 6000,
            backgroundColor: '#F6964F',
          },
          {
            id: 2,
            name: 'Meninos',
            value: 4000,
            backgroundColor: '#FEBF0F',
          },
        ],
        dataset: new DoughnutDataset([6000, 4000], ['#F6964F', '#FEBF0F']),
        cardTitle: 'Meninas/',
        cardTitle2: 'Meninos',
      };
    } else if (
      portalType === PORTAL_TYPES.REDE_ESCOLA &&
      currentFilterCard?.title ===
      PORTAL_FILTER_CARD_TITLES.REDE_ESCOLA_EMERGENCIES
    ) {
      return {
        cardLabels: [
          {
            id: 1,
            name: 'Meninas',
            value: 6000,
            backgroundColor: '#26C6DA',
          },
          {
            id: 2,
            name: 'Meninos',
            value: 4000,
            backgroundColor: '#0090FF',
          },
        ],
        dataset: new DoughnutDataset([6000, 4000], ['#26C6DA', '#0090FF']),
        cardTitle: 'Meninas/',
        cardTitle2: 'Meninos',
      };
    }
    return {};
  }

  buildChart(dataset: DoughnutDataset) {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart('doughnut-chart', {
      type: 'doughnut',
      data: {
        datasets: [dataset],
      },
      options: {
        cutout: '80%',
        responsive: false,
        maintainAspectRatio: false,
        events: ['mousemove'],
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });
  }
}
