<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>

<div class="add-img__container--grid mb-30">
  <div></div>
  <div>
    <div class="title-box">
      <div class="row border-bottom-0 pb-0">
        <div class="div-col-title col-lg-6 col-xl-6 col-12">
          <p class="portal-body__title mb-0">Adicionar imagem</p>
        </div>
        <div class="div-col-back col-lg-6 col-xl-6 col-12">
          <app-dynamic-button [buttonConfig]="configButtonBack"></app-dynamic-button>
        </div>
      </div>
    </div>
    <div class="portal-body__add-annoucement-card--bigger-padding">
      <app-image-upload (fileSent)="setFileUploaded()"></app-image-upload>
      <form class="mt-20" [formGroup]="formGroup" (ngSubmit)="saveAnnoucement()">
        <div class="mb-20">
          <label class="d-block mb-5 label" for="fileName">Nome do Arquivo</label>
          <input class="w-100 px-10" type=" text" id="fileName" formControlName="fileName" autocomplete="off" />
        </div>
        <div class="mb-20">
          <label class="d-block mb-5 label" for="link">Link</label>
          <input class="w-100 px-10" type="text" id="link" formControlName="link" autocomplete="off" />
        </div>
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg-3 col-xl-3 col-12">
              <label class="mb-5 label" for="order">Ordem</label>
              <select class="form-select" id="order" formControlName="order">
                <option selected value="0">Selecione</option>
                @for(order of annoucement.orders; track order.id){
                <option [value]="order.id">{{ order.name }}</option>
                }
              </select>
            </div>
            <div class="col-lg-9 col-xl-9 col-12">
              <div class="flex-grow-1">
                <label class="mb-5 label" for="status">Status</label>
                <select class="form-select" id="status" formControlName="status">
                  <option selected value="0">Selecione</option>
                  @for(status of annoucement.status; track status.id){
                  <option [value]="status.id">{{ status.name }}</option>
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <button (click)="saveAnnoucement()" class="mt-30 save-button" type="submit" [disabled]="!checkValidForm()">
              @if(this.formGroup.valid) {<img class="pr-7" src="assets/save_button_ic.svg" />} Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div></div>
</div>
