import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PORTAL_TYPES_NAMES } from '../../enums/portal-types-names.enum';
import { PORTAL_TYPES } from '../../enums/portal-types.enum';
import { PortalTypeService } from '../../services/portal-type.service';
import { SidebarOptionService } from '../sidebar/services/sidebar-option.service';
import { PORTAL_TYPE_URLS } from '../../enums/portal-types-urls.enum';
import { SidebarCollpseComponent } from '../sidebar-collpse/sidebar-collpse.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, SidebarCollpseComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  currentPortalType: PORTAL_TYPES =
    this._portalTypeService.currentPortalType$.value.type;
  currentPortalName: PORTAL_TYPES_NAMES =
    this._portalTypeService.currentPortalType$.value.name;

  constructor(
    private readonly _portalTypeService: PortalTypeService,
    private readonly _sidebarOptionsService: SidebarOptionService,
    private readonly _routerService: Router
  ) { }

  ngOnInit(): void {
    this.listensToPortalChanges();
  }

  listensToPortalChanges() {
    this._portalTypeService.currentPortalType$.subscribe((currentPortal) => {
      this.currentPortalType = currentPortal.type;
      this.currentPortalName = currentPortal.name;
    });
  }

  updatePortalType() {
    this._portalTypeService.updatePortalType();
    this._sidebarOptionsService.resetSidebarOption();

    if (this.currentPortalType === PORTAL_TYPES.RJ_190) {
      this._routerService.navigateByUrl(PORTAL_TYPE_URLS.RJ_190);
    } else if (this.currentPortalType === PORTAL_TYPES.REDE_MULHER) {
      this._routerService.navigateByUrl(PORTAL_TYPE_URLS.REDE_MULHER);
    } else {
      this._routerService.navigateByUrl(PORTAL_TYPE_URLS.REDE_ESCOLA);
    }
  }

  showCollpaseMenu() {
    const body = document.getElementsByTagName("body")[0].style.overflow = "hidden";
    const overflowHtml = document.getElementsByTagName("html")[0].style.overflow = "hidden";
  }
}
