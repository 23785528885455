import { Component, Inject } from '@angular/core';
import { PopAlertModel } from './pop-alert.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-pop-alert',
  standalone: true,
  imports: [
    MatDialogTitle,
    CommonModule
  ],
  templateUrl: './pop-alert.component.html',
  styleUrl: './pop-alert.component.scss'
})
export class PopAlertComponent {

  constructor(
    public dialogRef: MatDialogRef<PopAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopAlertModel) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
