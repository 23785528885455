<section class="portal-header--container">
  <app-sidebar-option-title-band></app-sidebar-option-title-band>
</section>
<section class="portal-body--container">
  <div class="container-fluid">
    <div class="row">
      @for(portalCard of portalCards; track portalCard.id ){
      <div class="col-lg-3 col-xl-3 col-12 card-itens">
        <app-filter-card (click)="getConfigTable()" [portalCard]="portalCard"></app-filter-card>
      </div>
      }
    </div>
  </div>
  <div class="container-fluid mt-20">
    <div class="row">
      <div class="col-12">
        <app-header-box-table [headerBoxTableSettings]="tableConfig" (searchClick)="searchAndFilterItens($event)"
          (clearSearchClick)="clearSearchTable()"></app-header-box-table>
      </div>
    </div>
  </div>

</section>
