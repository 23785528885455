import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { PORTAL_TYPE_URLS } from '../shared/enums/portal-types-urls.enum';
import { SIDEBAR_OPTIONS_URLS } from '../shared/enums/sidebar-option-urls.enum';
import { AuthService } from '../shared/services/auth/auth.service';
import { LocalStorageService } from '../shared/services/local-storage/local-storage.service';
import { PopAlertModel } from '../shared/components/pop-alert/pop-alert.model';
import { PopAlertComponent } from '../shared/components/pop-alert/pop-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../shared/services/loading/loading.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgxMaskDirective],
  providers: [provideNgxMask()],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup = new FormGroup([]);
  passType: string = 'password';
  private readonly authSecretKey = 'Bearer_Token';

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private localStorage: LocalStorageService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    this.showSlides(0);
    this.initializeFormGroup();
  }

  initializeFormGroup() {
    this.loginFormGroup = this._formBuilder.group({
      email: [, Validators.compose([Validators.required, Validators.minLength(7)])],
      senha: [, [Validators.required]],
    });
  }

  login() {
    this._router.navigate([PORTAL_TYPE_URLS.RJ_190 + '/' + SIDEBAR_OPTIONS_URLS.GENERAL_PANEL]);
    // if (!this.loginFormGroup.valid) return alert("Preencha corretamente os campos");
    // this.loadingService.present();
    // this._authService.login(this.loginFormGroup.value.email, this.loginFormGroup.value.senha).subscribe({
    //   next: (response) => {
    //     this.localStorage.setItem(this.authSecretKey, response.accessToken);
    //     this._router.navigate([PORTAL_TYPE_URLS.RJ_190 + '/' + SIDEBAR_OPTIONS_URLS.GENERAL_PANEL]);
    //   },
    //   error: (err) => {
    //     this.openDialogError(err.error.message);
    //   },
    // }).add(() => this.loadingService.dismiss());
  }

  showSlides(slideIndex: number) {
    let i;
    let slides = document.getElementsByClassName("slides") as any;
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > slides.length) { slideIndex = 1 }
    if (slides[slideIndex - 1]) {
      slides[slideIndex - 1].style.display = "block";
      setTimeout(() => {
        this.showSlides(slideIndex)
      }, 3000);
    }
  }

  openDialogError(message: string): void {
    let modelPopAlert: PopAlertModel = {
      text: message,
      type: 'error'
    };
    const dialogRef = this.dialog.open(PopAlertComponent, {
      data: modelPopAlert,
      position: { top: '52px' },
      hasBackdrop: false
    });

    setTimeout(() => {
      dialogRef.close();
    }, 2000);
  }
}
