import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnnoucementsService {
  constructor() {}

  getAnnoucements(annoucementQuery: { queryPage: number }) {
    return of({
      currentPage: annoucementQuery.queryPage,
      totalPages: 5,
      annoucementsList: [
        {
          id: 1,
          img: '',
          order: '1°',
          fileName: 'Banner Rede Mulher',
          link: 'https://translate.google.com/?source=osdd&sl=pt&tl=en&text=cadeado%20fechado&op=translate',
          status: 'active',
        },
        {
          id: 2,
          img: '',
          order: '2°',
          fileName: 'Banner Rede Escola',
          link: 'https://translate.google.com/?source=osdd&sl=pt&tl=en&text=cadeado%20fechado&op=translate',
          status: 'active',
        },
        {
          id: 3,
          img: '',
          order: '3°',
          fileName: 'Banner Natal',
          link: 'https://translate.google.com/?source=osdd&sl=pt&tl=en&text=cadeado%20fechado&op=translate',
          status: 'active',
        },
        {
          id: 4,
          img: '',
          order: '4°',
          fileName: 'Banner Reveillon',
          link: 'https://translate.google.com/?source=osdd&sl=pt&tl=en&text=cadeado%20fechado&op=translate',
          status: 'active',
        },
        {
          id: 5,
          img: '',
          order: '5°',
          fileName: 'Banner Verão',
          link: 'https://translate.google.com/?source=osdd&sl=pt&tl=en&text=cadeado%20fechado&op=translate',
          status: 'active',
        },
      ],
    });
  }
}
